export default class Toggle {
  private hamburger: Element;

  private pcMenu: Element;

  private spMenu: Element;

  private overlay: Element;

  constructor(hamburger: Element, pcMenu: Element, spMenu: Element, overlay: Element) {
    this.hamburger = hamburger;
    this.pcMenu = pcMenu;
    this.spMenu = spMenu;
    this.overlay = overlay;
  }

  onClick(): void {
    this.hamburger.addEventListener('click', () => {
      this.toggleMenu();
    });
  }

  onClickOverlay(): void {
    this.overlay.addEventListener('click', () => {
      this.toggleMenu();
    });
  }

  private toggleMenu(): void {
    this.hamburger.classList.toggle('is-open');
    if (this.pcMenu) this.pcMenu.classList.toggle('is-hidden');
    if (this.spMenu) this.spMenu.classList.toggle('is-hidden');
  }
}
