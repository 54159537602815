import Toggle from '../../modules/hamburger/toggle';

const hamburger = document.getElementById('js-hamburger');
const pcMenu = document.getElementById('js-pcMenu');
const spMenu = document.getElementById('js-spMenu');
const overlay = document.getElementById('js-menuOverlay');

if (hamburger && (pcMenu || spMenu)) {
  const toggleMenu = new Toggle(hamburger, pcMenu, spMenu, overlay);
  toggleMenu.onClick();
  if (overlay) toggleMenu.onClickOverlay();
}
